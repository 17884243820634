import { createRouter, createWebHashHistory } from 'vue-router'

const routers = [
  {
    path: '/',
    redirect: '/login/indexShipowner'
  },
  {
    //
    path: '/index',
    name: 'index',
    component: () => import('../views/index/index.vue'),
    meta: { title: '首页', index: 1.1 }
  },
  {
    // 店长登录
    path: '/login/indexShipowner',
    name: 'indexShipowner',
    component: () => import('../views/login/indexShipowner.vue'),
    meta: { title: '登录', index: 1.11 }
  },
  
  {
    path: '/login/404',
    name: '404',
    component: () => import('../views/login/404.vue'),
    meta: { title: '错误页', index: 1.12 }
  },
  {
    path: '/login/noRobot',
    name: 'noRobot',
    component: () => import('../views/login/noRobot.vue'),
    meta: { title: '错误页', index: 1.12 }
  },
  {
    path: '/login/login',
    name: 'loginLogin',
    component: () => import('../views/login/login.vue'),
    meta: { title: '登录', index: 1.11 }
  },
  
  {
    path: '/keywords/test',
    name: 'test',
    component: () => import('../views/keywords/test.vue'),
    meta: { title: '测试', index: 1.11 }
  },
  {
    path: '/keywords/list',
    name: 'lists',
    component: () => import('../views/keywords/list.vue'),
    meta: { title: '测试', index: 1.11 }
  },
  {
    path: '/group/openGroup',
    name: 'openGroup',
    component: () => import('../views/group/openGroup.vue'),
    meta: { title: '开群', index: 1.11 }
  },
  {
    path: '/choseGroup/index',
    name: 'choseGroup',
    component: () => import('../views/choseGroup/index.vue'),
    meta: { title: '选择群', index: 1.11 }
  },
  {
    path: '/keywords',
    name: 'keywords',
    component: () => import('../views/keywords/index.vue'),
    meta: { title: '关键词回复', index: 1.11 }
  },
  {
    path: '/gather',
    name: 'gather',
    component: () => import('../views/gather/index.vue'),
    meta: { title: '采集对象', index: 1.11 }
  },
  {
    path: '/indexTest',
    name: 'indexTest',
    component: () => import('../views/login/indexTest.vue'),
    meta: { title: '模拟测试', index: 1.11 }
  },
  {
    path: '/keywordsEdit',
    name: 'keywordsEdit',
    component: () => import('../views/keywordsEdit/index.vue'),
    meta: { title: '设置关键词回复', index: 1.111 }
  },
  {
    path: '/contentEdit',
    name: 'contentEdit',
    component: () => import('../views/contentEdit/index.vue'),
    meta: { title: '关键词内容设置', index: 1.111, keepAlive: true }
  },
  {
    path: '/addText',
    name: 'addText',
    component: () => import('../views/addText/index.vue'),
    meta: { title: '二级页面', index: 1.1 }
  },
  {
    path: '/editLink',
    name: 'editLink',
    component: () => import('../views/editLink/index.vue'),
    meta: { title: '编辑链接信息', index: 1.1111 }
  },
  {
    path: '/editMiniapp',
    name: 'editMiniapp',
    component: () => import('../views/editMiniapp/index.vue'),
    meta: { title: '我的店铺', index: 1.1111 }
  },
  {
    path: '/welcomeWords',
    name: 'welcomeWords',
    component: () => import('../views/welcomeWords/index.vue'),
    meta: { title: '入群欢迎语', index: 1.11 }
  },
  {
    path: '/welcomeEdit',
    name: 'welcomeEdit',
    component: () => import('../views/welcomeEdit/index.vue'),
    meta: { title: '欢迎语内容设置', index: 1.111, keepAlive: true }
  },
  {
    path: '/editText',
    name: 'editText',
    component: () => import('../views/editText/index.vue'),
    meta: { title: '编辑文本消息', index: 1.1111 }
  },
  {
    path: '/selectGroup',
    name: 'selectGroup',
    component: () => import('../views/selectGroup/index.vue'),
    meta: { title: '选择生效群', index: 1.111 }
  },
  {
    path: '/selectGroupGather',
    name: 'selectGroupGather',
    component: () => import('../views/selectGroupGather/index.vue'),
    meta: { title: '选择群', index: 1.111 }
  },
  {
    path: '/selectMemberGather',
    name: 'selectMemberGather',
    component: () => import('../views/selectMemberGather/index.vue'),
    meta: { title: '采集对象', index: 1.111 }
  },
  {
    path: '/todayPushGoods',
    name: 'todayPushGoods',
    component: () => import('../views/todayPushGoods/index.vue'),
    meta: { title: '今日推品', index: 1.11 }
  },
  {
    path: '/todayPushEdit',
    name: 'todayPushEdit',
    component: () => import('../views/todayPushGoods/edit.vue'),
    meta: { title: '推品详情', index: 1.111 }
  },
  {
    path: '/friendCircle',
    name: 'friendCircle',
    component: () => import('../views/friendCircle'),
    meta: {
      title: '自动发圈',
      index: 1.11
    }
  },
  {
    path: '/friendCircleEdit',
    name: 'friendCircleEdit',
    component: () => import('../views/friendCircle/edit'),
    meta: {
      title: '新增朋友圈'
    }
  },
  {
    path: '/friendCirclePermission',
    name: 'friendCirclePermission',
    component: () => import('../views/friendCirclePermission/friendCirclePermission'),
    meta: {
      title: '谁可以看',
      index: 1.12
    }
  }
]

const CreateRouter = createRouter

const router = new CreateRouter({
  history: createWebHashHistory(),
  routes: routers
})

export default router
