import axios from 'axios'
import { Dialog, Toast } from 'vant'

let baseURL = process.env.NODE_ENV === 'local' ? '/api' : process.env.VUE_APP_BASE_API // url = base url + request url
/*console.log(baseURL)
 // if (process.env.NODE_ENV === 'development') {
 //   baseURL = 'http://mallrobot-akc-webapi.test.csbilin.com/api'
 //   baseURL = `${location.origin}/api` // 代理测试地址（跨域代理在vite.config.js中修改）
 // } else {
 //   // baseURL = 'http://mallrobot-akc-webapi.test.csbilin.com/api' // 正式地址
 //   baseURL = 'https://akc-mallrobot-webapi.mbxdata.cn/api'
 // }*/

axios.defaults.baseURL = baseURL
axios.defaults.timeout = 100000
axios.defaults.withCredentials = false // 设置cross跨域 并设置访问权限 允许跨域携带cookie信息

// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    if (config.method === 'post' && config.isFile) {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      const formData = new FormData()
      const params = config.data
      Object.keys(params).forEach((key) => {
        formData.append(key, params[key])
      })
      config.data = formData
    }
    const token = localStorage.getItem('Token')
    if (token) {
      config.headers['UserToken'] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    Toast.clear()
    const code = response.data.code
    if (code === 0 || code === 1) {
      return response.data.data
    } else {
      if (code === -10001) {
        return Promise.reject(code)
      } else {
        Dialog.alert({
          title: '系统提示',
          message: response.data.message
        })
        console.log(response)
        return Promise.reject(new Error(response.data.message))
      }
      
    }
  },
  (error) => {
    if (error.message == "timeout of 100000ms exceeded") {
      Dialog.alert({
        title: '网络超时',
        message: '网络连接超时,请重新进入'
      })
    } else {
      if (error.name == "Error" && error.message == "Network Error") {
        return
      }
      Dialog.alert({
        title: error.name,
        message: error.message
      })
    }
    
    Toast.clear()
    return Promise.reject(error)
  }
)

/**
 * get
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get (url, params = {}) {
  params.ts = new Date().valueOf()
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * post
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post (url, data = {}, config = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, config).then(
      (response) => {
        resolve(response)
      },
      (err) => {
        reject(err)
      }
    )
  })
}
export {
  baseURL,
  axios as myAxios
}
